// import "./list.scss"
import NotificationService from "../../services/notification";
import { useEffect, useState } from "react";

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    getNotications();
  }, []);

  const getNotications = async () => {
    try {
      let { data } = await NotificationService.fetchNotifications();
      setNotificationData(data.data);
    } catch (err) {
      console.log(err);
      setNotificationData([]);
    }
  };

  return (
    <div className="notification-list">
      <div className="login-option not-container">
        {notificationData > 0 ? (
          <>
            {notificationData.map((not, i) => (
              <>
                <div className="notifiCont">
                  <img alt="avatar" className="profile" src={not.avatar_url} />
                </div>
                <div className="kid-details">{not.name}</div>
                <div className="kid-details sub">{not.expire_date}</div>
              </>
            ))}
          </>
        ) : (
          <h6 className="alert alert-danger my-3">No Notification</h6>
        )}
      </div>
    </div>
  );
};

export default Notification;
