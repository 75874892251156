import moment from "moment";
import React from "react";

const Card = ({ data, onViewProfile }) => {
  let { avatar_url, name, total_expenses, join_date, address, category_name, category_color, id } = data;
  return (
    <div className="card mb-3">
        <div className="kid-img">
            <img className="img-fluid" src={avatar_url ||'./child/noImg.png' } alt="" />
            <button onClick={()=> onViewProfile(id)} style={{background:category_color}}>View Profile</button>
        </div>
      <div className="card-body">
        <div className="d-flex align-items-center">
        <div className="name">{name}</div>
        <div className="ms-auto" style={{fontSize:13}}>Expenses <b>₹{total_expenses}</b></div>
        </div>
      </div>
    </div>
  );
};

export default Card;
