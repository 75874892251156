import React, { useEffect, useState } from "react";
import PaymentService from "../../services/payment";
import Datatable from "../../components/Datatable";
import Backdrop from "../../components/Backdrop";
import columnHeader from "./ColumnHeaderData";
import decodeToken from "../../utils/decodeToken";
import { toast } from "react-toastify";

const Payment = () => {
  const { user_id } = decodeToken();
  const [rowData, setRowData] = useState([]);
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    getAllPayments();
  }, []);

  const getAllPayments = async () => {
    try {
      const { data } = await PaymentService.fetchPaymentById(user_id);
      setRowData(data.data);
    } catch (err) {
      setRowData([]);
      console.log(err);
    }
  };
  const handleDelete = async (id) => {
    setBackdrop(true);
    try {
      await PaymentService.deletePayment(id);
      toast.success("Paymment deleted successfully");
      setBackdrop(false);
      getAllPayments();
    } catch (err) {
      toast.error("Paymment deleted successfully");
      setBackdrop(false);
      console.log(err);
    }
  };

  return (
    <div className="container">
      <div className="row my-3">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {rowData?.length > 0 ? (
                <Datatable
                  pageTitle={"Payment History"}
                  columnsData={columnHeader.columnsData}
                  actionColumn={columnHeader.actionHeader(handleDelete)}
                  rowData={rowData}
                />
              ) : (
                <h5 className="alert alert-danger text-center">
                  No Payment History Found
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
      {backdrop && <Backdrop />}
    </div>
  );
};

export default Payment;
