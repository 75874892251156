import HTTP from "../utils/HTTP";

class KidsService {
  fetchKids = () => {
    return HTTP.get("/admin/kid");
  };
  fetchKidById = (id) => {
    return HTTP.get(`/admin/kid/kidId/${id}`);
  };
  fetchPhotosByKidId = ({enrollment_id}) => {
    return HTTP.get(`/admin/kid/photo`, {
			params:{
				enrollment_id
			}
		});
  };
  fetchDocumentsByKidId = ({enrollment_id}) => {
    return HTTP.get(`/admin/kid/document`, {
			params:{
				enrollment_id
			}
		});
  };
}

export default new KidsService();
