import React, { useState } from "react";
import OtpInput from "react-otp-input";
import UserService from "../../services/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

const VerifyOtp = ({ setIsOpen, modalIsOpen, email }) => {
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();
  const onSubmit = async () => {
    if (otp) {
      try {
        let { data } = await UserService.verifyOtp({ otp, email });
        if (data.message) {
          toast.success(data.message);
          navigate("/login");
          setIsOpen(false);
        }
      } catch (err) {
        toast.error("OTP is not correct");
      }
    } else {
      toast.error("Please enter the otp");
    }
  };

  const onResend = async () => {
    if (email) {
      try {
        let { data } = await UserService.resendOtp({ email });
        if (data.message) {
          toast.success(data.message);
        }
      } catch (err) {
        toast.error("Something is went wrong!");
        console.log(err);
      }
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(40%, -50%)",
    },
    "@media screen and (max-width: 767px)": {
      transform: "translate(-48%, 16%)",
    },
  };
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {}}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h6 className="text-center otp-title">Plese enter your otp</h6>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{ width: "4rem", height: 40 }}
        />
        <div className="text-center mt-2">
          <button className="btn btn-secondary me-3" onClick={onResend}>
            Resend
          </button>
          <button className="btn btn-primary" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default VerifyOtp;
