import moment from "moment";
import { Chip } from "@mui/material";

const columnsData = [
  {
    field: "name",
    headerName: "Kid Name",
    flex: 1,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row?.avatar_url ? (
            <img
              className="cellImg img-thumbnail"
              src={params.row?.avatar_url}
              alt="avatar"
            />
          ) : (
            <i
              className="fa fa-user img-thumbnail img-circle"
              style={{ fontSize: 20, marginRight: 5 }}
            />
          )}

          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "full_name",
    headerName: "Montor Name",
    flex: 1,
    renderCell: (params) => {
      return <>{!params.row.mentor_id ? "Anonymous" : params.row.full_name}</>;
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    renderCell: (params) => {
      return <div>₹{params.row.amount}</div>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <div>
          <Chip label={params.row.status} variant="outlined" color="success" />
        </div>
      );
    },
  },
  {
    field: "payment_date",
    headerName: "Payment Date",
    flex: 1,
    renderCell: (params) => {
      return <div>{moment(params.row.payment_date).format("DD-MM-YYYY")}</div>;
    },
  },
];

let actionHeader = (handleDelete, setOpen, setSelectedRow) => {
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <a
              target="_blank"
              style={{
                border: "1px solid #fdc513",
                color: "#fdc513",
                borderRadius: 5,
                textDecoration: "none",
                padding: "2px 5px",
                cursor: "pointer",
              }}
              href={params?.row?.certificate_url}
              download
            >
              View Receipt
            </a>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return actionColumn;
};

const columnHeader = {
  columnsData,
  actionHeader,
};

export default columnHeader;
