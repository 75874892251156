import { ToastContainer, toast } from "react-toastify"; // <- add ToastContainer
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/Layout";
import Kids from "./pages/kids/kids";
import KidProfile from "./pages/kids/profile";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import SponseredKids from "./pages/sponseredKids/sponseredKids";
import Login from "./auth/login/login";
import Register from "./auth/register/register";
// import Checkout from "./pages/checkout/StripePayment";
import Checkout from "./pages/checkout/Checkout";
import Success from "./pages/checkout/Success";
import Cancel from "./pages/checkout/Cancel";
import Otp from "./auth/otp/otp";
import Payment from "./pages/mentor/payment";
import MentorProfile from "./pages/mentor/profile";
import Anonymous from "./pages/mentor/anonymous";

function App() {
  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<Layout><Kids /></Layout>} />
        <Route  path="/sponsored-kids" element={<Layout><SponseredKids /></Layout>} />
        <Route  path="/kid/:kid_id" element={<Layout><KidProfile /></Layout>} />
        <Route  path="/login" element={<Layout><Login /></Layout>} />
        <Route  path="/success/:transaction_id" element={<Layout><Success /></Layout>} />
        <Route  path="/cancel" element={<Layout><Cancel /></Layout>} />
        <Route  path="/checkout/:kid_id" element={<Layout><Checkout /></Layout>} />
        <Route  path="/payment-history" element={<Layout><Payment /></Layout>} />
        <Route  path="/mentor-profile" element={<Layout><MentorProfile /></Layout>} />
        <Route  path="/anonymous/:kid_id" element={<Layout><Anonymous /></Layout>} />
        
        <Route  path="/register" element={<Layout><Register /></Layout>} />
        <Route  path="/verifyOtp" element={<Layout><Otp /></Layout>} />
        <Route
        path="*"
        element={<Navigate to="/" replace />}
    />
      </Routes>
      <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar
        />
    </Router>
  );
}

export default App;
