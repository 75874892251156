import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import KidService from "../../services/kids";
import { useNavigate } from "react-router-dom";
import decodeToken from "../../utils/decodeToken";
import onRazorpay from "./RazorPay";
import { toast } from "react-toastify";

const Checkout = () => {
  const navigate = useNavigate();
  const [kid, setKid] = useState({});
  const [months, setMonths] = useState(1);
  const { user_id, full_name, email } = decodeToken();
  let { kid_id } = useParams();

  useEffect(() => {
    getKid();
  }, []);

  let getKid = async () => {
    try {
      const { data } = await KidService.fetchKidById(kid_id);
      setKid(data.data[0]);
    } catch (err) {}
  };

  let sponsorData = {
    kid_id: kid_id,
    kid_name: kid.name,
    amount: kid.total_expenses,
    mentor_id: parseInt(user_id),
    for_months: months,
    is_active: true,
    category_name: kid.category_name,
    state: kid.state,
    category_id: kid.category_id,
    mentor_name: full_name,
    email: email,
    is_anonymous: user_id ? false : true,
  };

  let totalAmt = months * kid.total_expenses;

  const handlePayment = async () => {
    if (!user_id) {
      navigate({
        pathname: `/anonymous/${sponsorData.kid_id}`,
        search: `?for_months=${months}`,
      });
    } else {
      onRazorpay(sponsorData)
    }
  };

  return (
    <div className="container my-3">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4">
              <img
                width={500}
                height={150}
                src={kid.avatar_url || "../child/noImg.png"}
                className="rounded img-fluid"
              />
            </div>
            <div className="col-sm-8">
              <h5 className="text-capitalize mt-3">
                <b>{kid.name}</b>
              </h5>
              <h6 className="my-3">
                <span className="text-muted">Sponsoring Amount:</span>{" "}
                <b>
                  ₹{kid.total_expenses ? kid.total_expenses : kid.basic_amount}
                </b>
              </h6>
              <select
                className="form-control mb-4"
                style={{ width: 230 }}
                value={months}
                onChange={(e) => setMonths(e.target.value)}
              >
                <option value={1}>1 Month</option>
                <option value={2}>2 Months</option>
                <option value={3}>3 Months</option>
                <option value={4}>4 Months</option>
                <option value={5}>5 Months</option>
                <option value={6}>6 Months</option>
                <option value={7}>7 Months</option>
                <option value={8}>8 Months</option>
                <option value={9}>9 Months</option>
                <option value={10}>10 Months</option>
                <option value={11}>11 Months</option>
                <option value={12}>12 Months</option>
              </select>
              <div>
                <>
                  <div className="mb-3">
                    <span className="text-muted">Total Sponsoring Amount:</span>{" "}
                    <b>₹{totalAmt ? totalAmt : sponsorData.amount}</b>
                  </div>
                  <button
                    className="btn btn-primary"
                    style={{ width: 230 }}
                    onClick={() => handlePayment()}
                  >
                    Checkout
                  </button>
                </>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
