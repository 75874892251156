import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { toPng } from "html-to-image";
import imgUploadService from "../../services/uploadImg";
import PaymentService from "../../services/payment";
import { useParams } from "react-router-dom";

const CertificateHtml = () => {
  const [certificateUrl, setCertificateUrl] = useState();
  const [paymentData, setPaymentData] = useState({});

  const elementRef = useRef(null);
  const params = useParams(null);
  console.log(params.transaction_id);

  useEffect(() => {
    if (params.transaction_id) {
      PaymentService.fetchPaymentByTransId(params.transaction_id)
        .then((res) => {
          setPaymentData(res?.data?.data[0]);
          console.log(res?.data?.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params.transaction_id]);

  useEffect(() => {
    if (paymentData) {
      if(paymentData?.certificate_url){
        setCertificateUrl(paymentData?.certificate_url)
      }
      else{
          htmlToImageConvert();
        }
    }
  }, [paymentData]);

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then(async (dataUrl) => {
        setCertificateUrl(dataUrl);
        try {
          let folder = "users";
          const uploadResult = await imgUploadService.uploadImg({
            image: dataUrl,
            folder,
          });
          try {
            console.log(uploadResult.data);
            let paymentObj = {
              certificate_url: uploadResult.data,
              transaction_id: paymentData.transaction_id,
              email: paymentData.email,
            };
            const payment = await PaymentService.updatePaymentReceipt(
              paymentObj
            );
            if (payment) {
              console.log("Certificate created");
            }
          } catch (err) {
            console.log(err);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onReceiptDownload = () => {
    const link = document.createElement("a");
    link.download = "my-image-name.png";
    link.href = certificateUrl;
    link.click();
  };
  return (
    <>
    <div className="d-flex align-items-center">
      <h5>Certificate</h5>
      {certificateUrl && (
        <Button
          color="primary"
          sx={{ mr: 1, mt: 1 }}
          variant="contained"
          className="receipt-download ms-auto mb-2"
          onClick={onReceiptDownload}
        >
          Download
        </Button>
      )}
    </div>
      
      <div className="receipt" ref={elementRef}>
        <div className="receipt-header">
          <div className="logo">
            <img src="../img/logo.png" height={100} />
          </div>
          <div className="content">
            <h2>SHAAGIRD FOUNDATION</h2>
            <p>
              H No. 399 Prop No. 87G/F, Main 25 Ft Road, Shiv Vihar,
              <br />
              Karawal Nagar, North East Delhi 11009
            </p>
            <p>contact@shaagird.org</p>
            <p>
              <b>PAN:</b>ABJCS7197K <b style={{ marginLeft: 10 }}>CIN:</b>{" "}
              U80901DL2022NPL406795
            </p>
          </div>
        </div>
        <div className="receipt-main">
          <h1>DONATION RECEIPT</h1>
          <div style={{ position: "relative", fontStyle: "italic" }}>
            <img className="quote-left" src="../img/quoteLeft.png" />
            <p style={{ position: "relative", zIndex: 2 }}>
              We at Shaagird Foundation express gratitude for the thoughtful and
              compassionate
              <br />
              gesture on your part. This valuable contribution will greatly help
              us in restoring smiles.
            </p>
            <img className="quote-right" src="../img/quoteRight.png" />
          </div>
          <div className="form-content">
            <div className="field">
              <span>Receipt No:</span>
              <span>{paymentData?.receipt_no}</span>
            </div>
            <div className="field">
              <span>Received with Thanks from:</span>
              <span>{paymentData?.mentor_name}</span>
            </div>
            <div className="field">
              <span>Address:</span>
              <span>{paymentData?.address}</span>
            </div>
            <div className="field">
              <span>The Sum of Rupees:</span>
              <span>₹{paymentData?.amount}</span>
            </div>
            <div className="field">
              <span>For/As Donations/Grant:</span>
              <span>Donation</span>
            </div>
            <div className="field">
              <span>Through:</span>
              <span>Paid Online</span>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div className="field">
                <span>Transaction:</span>
                <span>{paymentData?.transaction_id}</span>
              </div>
              <div className="field" style={{ marginLeft: 15 }}>
                <span>Date:</span>
                <span>{paymentData?.payment_date}</span>
              </div>
            </div>
            <div className="field">
              <span>PAN Number:</span>
              <span>{paymentData?.pan_number}</span>
            </div>
          </div>
        </div>
        <div className="receipt-footer">
          <p>
            <b>Note:</b> Subject to realisation of cheque
            <br />
            Provisional Approval U/S 80G of Income Tax Act 1961 Under <br />
            Unique Registration Number <b>ABJCS7197KE20231</b>
          </p>
          <div className="signature">
            <div>
              <b>Authorised Signatory</b>
              <br />
              Shaagird Foundation
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CertificateHtml;
