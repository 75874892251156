import React, { useEffect, useState } from "react";
import SponsoredService from "../../services/sponsored";
import decodeToken from "../../utils/decodeToken";
import SponseredKidCard from "./components/Card";
import { useNavigate } from "react-router-dom";

const SponsoredKids = () => {
  const { user_id } = decodeToken();
  const [sponsoredKids, setSponsoredKids] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getAllSponsoredKids();
  }, []);
  const getAllSponsoredKids = async () => {
    try {
      const { data } = await SponsoredService.fetchSponsorKidsByMentor(user_id);
      setSponsoredKids(data.data);
    } catch (err) {
      setSponsoredKids([]);
      console.log(err);
    }
  };

  const onViewProfile = (id) => {
    navigate(`/kid/${id}?isSponsored=true`);
  };

  return (
    <div className="container my-3">
      <div className="card">
        <div className="card-body p-4">
          <h5>
            <b>Sponsored Kids</b>
          </h5>
          <div className="row mt-2">
            {sponsoredKids?.length > 0 ? (
              sponsoredKids?.map((kid) => (
                <div className="col-sm-4">
                  <SponseredKidCard
                    user_id={user_id}
                    onViewProfile={onViewProfile}
                    data={kid}
                  />
                </div>
              ))
            ) : (
              <h5 className="alert alert-danger text-center">
                No Sponsored Kid Found
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsoredKids;
