import React, { useState } from "react";
import UserService from "../../services/user";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyOtp from "../otp/otp";
import { TextField, Button } from "@mui/material";
import "./login.css";
import Backdrop from "../../components/Backdrop";

const Login = () => {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup.string("Enter email").email().required("Email is required"),
    password: yup.string("Enter password").required("Password is required"),
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setBackdrop(true);
      try {
        let { data } = await UserService.loginUser(values);
        if (data.token) {
          localStorage.setItem("loginToken", data.token);
          toast.success("Login successfully");
          navigate("/sponsored-kids");
        } else if (!data.is_verified) {
          toast.success(data.message);
          setIsOpen(true);
          setBackdrop(false);
        }
      } catch (err) {
        toast.error("Please check you credential");
        setBackdrop(false);
      }
      // setIsSignUpMode(true);
    },
  });

  return (
    <div className="loginContainer">
      <div className="forms-container">
        <div className="sign-in-form loginForm">
          <h2 className="title">Sign in</h2>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="dense"
              size="small"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              type="password"
              id="password"
              name="password"
              label="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="dense"
              size="small"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />

            <div className="text-right">
              <Button
                fullWidth
                sx={{ mr: 1, mt: 1 }}
                variant="contained"
                type="submit"
                className="btn-primary"
              >
                Login
              </Button>
              <p className="mt-3 mb-0">
                you don't have account <Link to="/register">Register</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      {modalIsOpen && (
        <VerifyOtp
          email={formik.values.email}
          setIsOpen={setIsOpen}
          modalIsOpen={modalIsOpen}
        />
      )}
      {backdrop && <Backdrop/>}
    </div>
  );
};

export default Login;
