
import HTTP from '../utils/HTTP';

class SponsoredService {
	fetchSponsorKidsByMentor = (user_id) => {
		return HTTP.get('/admin/sponsor/kids',{
			params:{
				mentor_id:user_id,
			}
		})
	};

	addSponsor = (data) => {
		return HTTP.post('/admin/sponsor/add',data)
	};
}

export default new SponsoredService();
