import React, { useEffect, useState } from "react";
import decodeToken from "../../utils/decodeToken";
import CertifcateHtml from "../mentor/certifcateHtml";

import { Link } from "react-router-dom";

function Success() {
  const { email } = decodeToken();

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-md-5 text-center">
              <div className="card">
                <div className="card-body p-5">
                  <i
                    className="fa fa-check-circle mb-3"
                    style={{ color: "green", fontSize: 60 }}
                  ></i>
                  <h4 style={{ color: "green" }}>
                    <b>Your payment is successful.</b>
                  </h4>
                  <h5 className="text-muted">Thanks for your sponsorship!</h5>
                  <p>
                    We have sent an email to your email address <b>{email}</b>{" "}
                    containing the details of your sponsorship. Please check
                    your inbox.
                  </p>
                  <Link
                    style={{ height: "auto" }}
                    className="btn btn-primary"
                    to="/sponsored-kids"
                  >
                    Go to Main Page
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-7">
            <div className="card">
                <div className="card-body pb-2 position-relative">
              <CertifcateHtml />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
