import HTTP from '../utils/HTTP';

class ImgUploadService {
	uploadImg = ({image, folder}) => {
		return HTTP.post('/uploadImage', { image, folder});
	};

    deleteImg = (public_id, folder) => {
		return HTTP.post('/deleteImage', {public_id, folder});
	};
}

export default new ImgUploadService();
