import HTTP from '../utils/HTTP';

class UserService {
	loginUser = data => {
		return HTTP.post('/user/login', { ...data });
	};
	register = data => {
		return HTTP.post('/user/register', { ...data });
	};
	verifyOtp = data => {
		return HTTP.post('/user/verifyotp', data);
	};
	resendOtp = data => {
		return HTTP.post('/user/resendotp', data);
	};
	updateUser = data => {
		return HTTP.put('/user/update', { ...data });
	};
	fetchUser = (user_id) => {
		return HTTP.get('/user/mentor/id',{
			params:{
				user_id
			}
		})
	};
	
}

export default new UserService();
