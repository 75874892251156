import HTTP from '../utils/HTTP';

class CategoriesService {
	fetchCategories = () => {
		return HTTP.get('/admin/category')
	};
	addCategory = (data) => {
		return HTTP.post('/admin/category/add', data)
	};
	updateCategory = (data) => {
		return HTTP.put(`/admin/category/update`, data)
	};
	deleteCategory = (id) => {
		return HTTP.delete(`/admin/category/delete/${id}`)
	};
	
}

export default new CategoriesService();
