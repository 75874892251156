import React from "react";
import { Link, useSearchParams } from "react-router-dom";

function Cancel() {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-sm-12 text-center">
          <div className="card">
            <div className="card-body p-5">
              <i
                className="fa fa-close mb-3"
                style={{ color: "red", fontSize: 60 }}
              ></i>
              <h4 style={{ color: "red" }}>
                <b>Oops! Your payment has been cancelled.</b>
              </h4>
              <Link to="/" className="text-warning">
                Go to Home page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cancel;
