import HTTP from '../utils/HTTP';

class ExpencesService {
	fetchExpencesKid = (id) => {
		return HTTP.get('/admin/expenditure',{
			params:{
				kid_id:id
			}
		})
	};
}

export default new ExpencesService();
