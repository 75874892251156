import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Autoplay, EffectCoverflow } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";

const Banner = () => {
  return (
    <section className="banner">
      <div className="container">
        {/* <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img src="./child/banner/allkids.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </section> */}
        <div className="row">
          <div className="col-12 mx-auto">
            <Swiper
              loop={true}
              speed={1000}
              modules={[Autoplay, EffectCoverflow]}
              effect="coverflow"
              navigation
              autoplay={{
                delay: 1000,
              }}
              grabCursor={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
                slideShadows: false,
              }}
              centeredSlides={true}
              className="swiper-conainer"
              slidesPerView={3}
            >
              <SwiperSlide
                style={{
                  backgroundImage: "url()",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img src="./child/banner/1.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/2.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/3.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/4.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/5.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/6.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/7.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/8.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/9.jpg" className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="./child/banner/10.jpg" className="img-fluid" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
