import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";

const Backdrop = ()=>{
    return(
        <div className='backdrop-overlay'>
            <CircularProgress />
        </div>
    )
}

export default Backdrop;