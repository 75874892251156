import HTTP from '../utils/HTTP';

class PaymentService {
	fetchPaymentById = (user_id) => {
		return HTTP.get('/payment',{
			params:{
				user_id
			}
		})
	};
	fetchPaymentByTransId = (transaction_id) => {
		return HTTP.get(`/payment/${transaction_id}`)
	};
	addPayment = (data) => {
		return HTTP.post('/payment/add',data)
	};
	razorPayment = (data) => {
		return HTTP.post('/payment/razorpay',data)
	};
	verifyPayment = (data) => {
		return HTTP.post('/payment/varify',data)
	};
	updatePaymentReceipt = (data) => {
		return HTTP.put('/payment/update-receipt',data)
	};
}

export default new PaymentService();
