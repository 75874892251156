import moment from "moment";
import React from "react";

const SponseredKidCard = ({ data, onViewProfile, user_id }) => {
  let {
    avatar_url,
    amount,
    for_months,
    name,
    date_of_birth,
    join_date,
    address,
    category_name,
    mentored_on_date,
    full_name,
    category_color,
    id,
    kid_id,
  } = data;

  return (
    <div className="card mb-3">
      <div className="kid-img">
        <img
          className="img-fluid"
          src={avatar_url || "./child/noImg.png"}
          alt=""
        />
        <button
          onClick={() => onViewProfile(kid_id)}
          style={{ background: category_color }}
        >
          View Profile
        </button>
      </div>
      <div className="card-body">
        <div className="name text-center text-capitalize">
          <h5>
            <b>{name}</b>
            <br />
            <b
              className="badge"
              style={{ fontSize: 12, background: category_color }}
            >
              {category_name}
            </b>
          </h5>
        </div>
        <div style={{ fontSize: 12 }} className="d-flex align-items-center">
          <div>
            <span className="text-muted">Mentor</span>{" "}
            <b>{full_name ? full_name : "Anonymous"}</b>
          </div>
          <div className="ms-auto">
            {" "}
            <span className="text-muted">Mentoring Date</span>{" "}
            <b>{moment(mentored_on_date).format("DD-MM-YYYY")}</b>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div style={{ fontSize: 12 }}>
            <b className="badge badge-info" style={{ fontSize: 12 }}>
              {for_months} Months
            </b>
            <br />
          </div>
          <div className="mt-1 ms-auto">
            <b className="badge badge-info" style={{ fontSize: 12 }}>
              ₹{amount}
            </b>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponseredKidCard;
