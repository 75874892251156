import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import decodeToken from "../utils/decodeToken";
import { toast } from "react-toastify";
import { BsFillBellFill } from "react-icons/fa";

import Notification from "../pages/notification/Notification";

const Navbar = () => {
  let { user_id, full_name, avatar_url } = decodeToken();
  let [toggleLogin, setToggleLogin] = useState(false);
  let [toggleNotification, setToggleNotification] = useState(false);

  const onLogout = () => {
    localStorage.removeItem("loginToken");
    toast.success("Logout successfully");
  };
  return (
    <div className="nav">
      <NavLink
        to={"/"}
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        Sponsor Kids
      </NavLink>
      <NavLink
        to={"/sponsored-kids"}
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        Sponsored Kids
      </NavLink>
      {user_id ? (
        <>
          <span
            onClick={() => setToggleNotification(!toggleNotification)}
            className="position-relative"
          >
            <span className="me-2 cursor-pointer">
              <i className="fa fa-bell-o text-warning" aria-hidden="true"></i>
            </span>
            {toggleNotification && <Notification />}
          </span>

          <span
            onClick={() => setToggleLogin(!toggleLogin)}
            className="position-relative"
          >
            <span className="me-2 cursor-pointer">
              {avatar_url ? (
                <img width={20} src={avatar_url} alt="avatar" />
              ) : (
                <i className="fa fa-user-circle text-warning"></i>
              )}{" "}
              <span className="d-none d-sm-inline">{full_name}</span>{" "}
              <i className="fa fa-caret-down"></i>
            </span>
            {toggleLogin && (
              <div className="login-option">
                <ul>
                <li>
                  <NavLink to={"/mentor-profile"}>
                    <i className="fa fa-cog" aria-hidden="true">
                    </i>
                      Profile
                  </NavLink>
                  </li>
                  <li>
                  <NavLink to={"/payment-history"}>
                    <i className="fa fa-money" aria-hidden="true">
                    </i>
                    Payments
                  </NavLink>
                  </li>
                  <li onClick={onLogout}>
                    <i className="fa fa-power-off"></i> Logout
                  </li>
                </ul>
              </div>
            )}
          </span>
        </>
      ) : (
        <NavLink
          to={"/login"}
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
        >
          Login
        </NavLink>
      )}

      <a
        href="https://shaagird.org/donate/"
        target="_blank"
        className="donate-now btn-primary"
      >
        <span className="d-none d-sm-inline"> Donate Now</span>&nbsp;
        <i class="fa fa-heart-o d-inline" aria-hidden="true"></i>
      </a>
    </div>
  );
};

export default Navbar;
